import { Flex, Image, Text  } from '@aws-amplify/ui-react'
import { Grid, useMediaQuery } from '@mui/material'
import React, { useRef } from 'react'
import NavBar from './NavBar'
// import Modal from 'react-modal'
import {motion, useScroll, useTransform } from 'framer-motion'
// import AccessBox from './AccessBox'
import { useNavigate } from 'react-router-dom'
import img5 from "./flyers/firstskate.jpeg"
import img2 from "./flyers/BrownsvilleClinic.JPG"
import img3 from "./flyers/schoolDrive.JPG"
import img4 from "./flyers/plug.JPG"
import img1 from "./flyers/workshop.jpeg"



export default function Starter() {
  
 

  React.useEffect(() => {
    window.scroll(0,0)
  }, [])

  const matches = useMediaQuery('(min-width:800px)');
  const hMatches = useMediaQuery('(min-height:490px)');
  // const [showFlyer, setshowFlyer] = React.useState(false)
  // const closeFlyer = () => {
  //   setshowFlyer(false)
  // }
  // const [showAccessBox, setAccessBox] = React.useState(false)
  // const closeAccessBox = () => {
  //   setAccessBox(false)
  // }
  const navigate = useNavigate()
  

  const handleDonationClicked = () => {
  window.open("https://donate.stripe.com/14kdSG6OF7Crcb65kk")

  }
  const homeLogoRef = useRef(null)
  
  const { scrollYProgress } = useScroll(
    {
    target: homeLogoRef,
    offset: ["start start","end start"]
  },

)

  

  const rotateAffect = useTransform(scrollYProgress, [0,1], ["0deg","360deg"])
  const marginLeftAffect = useTransform(scrollYProgress, [0,1], ["0px","600px"])
  const opacityAffect = useTransform(scrollYProgress, [0,1], ["1", "0.1"])

  
  

  // useMotionValueEvent(scrollYProgress, "change", (latest) => {
  //   console.log("Latest", latest)
  //   console.log("Scroll Progress", scrollYProgress.current)
  // })


// const imageList = [{
//   page : "/skateevent.jpg",
  
// },
// {page : "/skateevent2.jpg"}
// ]


  return (
    <Grid container
    position="relative"

    width="100%"
    height="100%"  
    backgroundColor="#0C8645"
   

    >
     <div 
      style={{
        backgroundImage:"radial-gradient(130% 100% at 0% 0%, #737373,#0C8645)"
      }}
      className='absolute h-[100vh] w-full overflow-hidden'
      
      />

    <Grid container
    display="flex"
    position="relative"
    height="100vh"
    width="100vw"
  
    direction="column"
    wrap="nowrap"
    // backgroundColor="pink"
 
    // overflow="hidden"
    sx={{
      overflowY:"scroll",
      overflowX:"hidden"
    }}
   
     
    >
  
    
    
     

        <NavBar />
   
    <motion.div
      ref={homeLogoRef}
      style={{
        rotate: rotateAffect,
        marginLeft: marginLeftAffect,
        scale: opacityAffect
      }}
      className='relative flex w-[210px] min-h-[200px] max-h-[600px] overflow-hidden '
      initial={{
        rotate: '0deg',
        marginLeft: "-200px",
        position:"relative"
      }}
      animate={{
        rotate:"360deg",
        marginLeft: "0px"

      }}

      // exit={{
      //   marginLeft:"1000px",
      //   rotate:"0deg",

      // }}
      
      transition={{
        duration: 3,
        type:"spring"
       
      }} 
      >
        <Image
    src="/4.png"
    alt="about logo"
    // backgroundColor="red"
    // width="200px"
    // height="800px"
    width="200px"
    height="230px"
    objectFit="cover"
    position="relative"
    />
    </motion.div>
   
    


      <Grid container item
    position="relative"
    display="flex"
    direction="column"
    height="100%"
  

    
  
  
    // backgroundColor="blue"
    
    
    wrap='nowrap'
    >
     
       

      <section
  
       
       className='flex flex-col font-[5px] w-full pt-5 pb-5  pl-8 pr-8  '>

        <motion.section
        
        initial={{
        
      
      
      
        

          opacity: 0.2,
          scale: 0.8
          
        }}
        whileInView={{
          opacity: 1,
          scale: 1
          
         
          
        }}
        
        transition={{ duration: 2 }}>

        
        
        <Text
         className='relative sm: text-3xl md:text-[63px]' 
     
         
        //  width="100%"
     
         fontSize={matches ? "45px" : "25px"}
          color="white"
         >
        ENVIRONMENTAL HOOD RESTORATION
        </Text>
        </motion.section>

        <div className='  sm:text-xl text-white sm:pt-0 md:text-[26px] md:pt-0 '>
        FOR THE HOOD, FROM THE HOOD.
        </div>
       
      </section>



{/* Upcoming Events and Donte button Grid */}
      <Grid item 
      display="flex"
      paddingTop="25px"
      
      paddingLeft="25px"
      paddingRight="25px"
      // direction={matches ? "row" : "column"}
      
    
      width="100%"
    
      alignItems="flex-start"
    
      gap="15px"
      
  
      > 
     
        {/* <a href='#upcoming-events'> */}
        <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => {
          const upcomingEvent = document.getElementById('upcoming-events')
          upcomingEvent.scrollIntoView({behavior: "smooth"})
          // setshowFlyer(true)
        }}
        

      
        >
           <Text
           display="flex"
           justifyContent="center"
           alignItems="center"
           paddingLeft="10px"
           paddingRight="10px"
          //  width={matches ? "160px" : "120px"}
           width="160px"
           height="60px"
           backgroundColor="rgba(32, 78, 41, 0.8)"
           borderRadius="5px"
           textAlign="center"
           fontWeight="bold"
           color="white"
           className='focus:border-green active:border-green '
           >
            Upcoming Events
           </Text>

        {/* <Button
        width="160px"
        height="60px"
        color="white"
        display="flex"
        textAlign="center"
        
        
        // paddingLeft="5px"
        className='focus:border-green active:border-green '
        children='Upcoming Event'
        backgroundColor="green"
        borderColor="green"
        onClick={() => setshowFlyer(true)}
        > 
        

        </Button> */}
        </motion.button>
        {/* </a> */}
        

        
        <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={handleDonationClicked}
        

      
        >
           <Text
           display="flex"
           justifyContent="center"
           alignItems="center"
           width="160px"
           height="60px"
           backgroundColor="rgba(32, 78, 41, 0.8)"
           borderRadius="5px"
           textAlign="center"
           fontWeight="bold"
           color="white"
           className='focus:border-green active:border-green '
           >
           Donate
           </Text>
        
        {/* <Button
        width="160px"
        height="60px"
        color="white"
        // paddingLeft="5px"
        className='focus:border-green active:border-green '
        children='Donate'
        backgroundColor="green"
        onClick={handleDonationClicked}
        borderColor="green"> 

        </Button> */}
        </motion.button>

      </Grid>

     

      </Grid>

{/* Lawyer Stuff */}
      <Flex
    
      position="relative"
      
      
      height="100%"
      
   
      
      width="100%"
    
   
      
      // backgroundColor="brown"
      paddingBottom="20px"
      alignItems="flex-end"
     
    
      >

     
        
      <p 
      // className='relative flex w-[100%] h-auto text-white justify-center items-end pl-5 pr-5 pt-10 text-[14px] '
      className='relative pt-10 pr-3 pl-3 h-auto text-white text-[14px]'
      >
       
        If you're seeking to obtain our most recent financial report that has been formally submitted, we encourage you to get in touch with our team. Please direct your request to our official email address at info@environmentalhoodrestoration.org / environmentalhoodrestoration@gmail.com, and we will assist you by providing you with the requested document. Your interest in our financial affairs is greatly appreciated, and we're here to ensure you have all the information you need.
        
      </p>
      


     

      </Flex>
      
     
       


    
      
    

       
     {/* <OrganizationForm /> */}
    {/* <CountDown/> */}

        



    {/* <Collection
    items={imageList}
    itemsPerPage={1}
    type='list'
    width="300px"
    height="auto"
    display="flex"
    isPaginated
    >
       {(item, index) => (
        <Image
        key={index}
        src={item.page} 
        height="330px"
        />


        {/* <Flex
        position="relative"
        display="flex"
        width="100vw"
        height="250px"
        backgroundColor="yellow"
        >

        </Flex> */}

    {/* </Collection> */}

   
      {/* <Modal
      isOpen={showFlyer}
      onRequestClose={closeFlyer}
      style={{
        overlay: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: "scroll",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        zIndex: 999,
        // paddingTop:"20px",
        // paddingBottom:"20px",
        padding:"30px 30px 30px 30px"
      },

        content: {
        position: 'relative',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        border: '0px solid #ccc',
      //   background: 'blue',
      //   width: "302px",
        // overflow: 'auto',
        width:"auto",
        backgroundColor:"rgba(255,255,255,.9)",
        // WebkitOverflowScrolling: 'touch',
        overflow: "hidden",
        borderRadius: '15px',
        height:"auto",
        // maxWidth:"600px",
        
        // paddingBottom:"20px",
        boxShadow:"0px 5px 15px rgba(0, 0, 0, 0.2)",
        outline: 'none',
        padding:"0px"
        }
      }}
      >

        <Grid container
        
        position="relative"
        display="flex"
        direction="column"
        // className=' flex-col '
        

        
        width="auto"
        height="auto"
        // backgroundColor="yellow"
        
        >
  <Image
  // src='/skteevent.JPG'
  src='/fooddrive.jpg'
  height="480px"
  width="600px"
  />

  <Flex
  position="relative"
  justifyContent="center"
  alignItems="center"
  direction="column"
  paddingLeft="5px"
  paddingRight="5px"
  paddingTop="25px"
  paddingBottom="25px">
  <Text 
  width="100%"
  textAlign="center"
  fontSize="20px"
  
  >
    Register your skater now!
  </Text>
   <motion.button
       

       whileHover={{ scale: 1.1 }}
       whileTap={{ scale: 0.9 }}
       onClick={() => {
           navigate('/SkaterSignUp')
       }}>
           <Text
           display="flex"
           justifyContent="center"
           alignItems="center"
           width="300px"
           height="40px"
           backgroundColor="rgba(32, 78, 41, 0.8)"
           borderRadius="5px"
           textAlign="center"
           fontWeight="bold"
           color="white"
           >

           Sign Up Skater
           </Text>
  </motion.button>
  </Flex>
        </Grid>
      </Modal>  */}

      {/* <Modal
      isOpen={showAccessBox}
      onRequestClose={closeAccessBox}
      style={{
        overlay: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: "scroll",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        zIndex: 999,
        // paddingTop:"20px",
        // paddingBottom:"20px",
        padding:"0px 0px 0px 0px"
      },

        content: {
        position: 'relative',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        border: '0px solid #ccc',
      //   background: 'blue',
      //   width: "302px",
        // overflow: 'auto',
        width:"auto",
        backgroundColor:"rgba(255,255,255,.8)",
        // WebkitOverflowScrolling: 'touch',
        overflow: "hidden",
        borderRadius: '15px',
        height:"auto",
        // maxWidth:"600px",
        
        // paddingBottom:"20px",
        boxShadow:"0px 0px rgba(0, 0, 0, 0.2)",
        outline: 'none',
        padding:"0px 0px 0px 0px"
        }
      }}
      >
        <AccessBox />
      </Modal> */}

    </Grid>

{/* About Page */}
    <Grid container
    position="relative"
    display="flex"
    width="100%"
    height="100vh"
    direction="column"
    wrap="nowrap"
    paddingTop="10px"
    paddingBottom="20px"
    sx={{
      overflowX:"hidden",
      overflowY:"scroll"
    }}
    // overflow="hidden"
    
    // backgroundColor="blue"

    >

      <Grid
      position="absolute"
      display="flex"
      width="100%"
      height="100%"
      zIndex="99999"
      marginTop="-10px"
      justifyContent="center"
      alignItems="center"
      
      // backgroundColor="teal"
      >
        <motion.div
      // className='
      
      //  bg-teal-500'
      initial={{
        opacity: 0.3,
        scale: 0.2
     
      }}
        whileInView={{
        
        opacity: 1,
        scale: 1

      }}
      transition={{
        duration: 3,
        type:"spring"
       
      }} 
   
      

    
      >
        <Image
    src="/flowerthing.png"
    alt=""
    // backgroundColor="red"
   
    width={matches ? "800px" : "600px"}
    display={hMatches ? "block" : "none"}
    height='auto'
    objectFit="cover"
    position="relative"
    
    />
      </motion.div>

      </Grid>

       <section
  
       
  className='flex flex-col font-[5px] w-[100%] pt-5 pb-5  pl-8 pr-8  '>

   <motion.section
   
   initial={{
   
 
 
 
   

     opacity: 0.3,
     scale: 0.8
     
   }}
   whileInView={{
     opacity: 1,
     scale: 1
     
    
     
   }}
   
   transition={{ duration: 2 }}>

   
   
   <Text
    className='relative sm: text-3xl md:text-[63px]' 

    
   //  width="100%"

    fontSize={matches ? "35px" : "25px"}
     color="white"
    >
   OUR MISSION
   </Text>
   </motion.section>

   <div className='  sm:text-xl text-white sm:pt-0 md:text-[22px] md:pt-0 '>
   We support well rounded leaders who prioritize health, community, humanity and personal excellence.
   </div>
  
       </section>

       {/* <Flex
      position="relative"
      width="100%"
      // backgroundColor="#0C8645"
      backgroundColor="pink"

      >
      <motion.div
      // className='
      
      //  bg-teal-500'
      initial={{
        rotate: '0deg',
 
        marginLeft: "-10px",
        scale: 0.2
     
      }}
        whileInView={{
        
        rotate:"360deg",
        marginLeft: "38vw",
        scale: 1

      }}
      transition={{
        duration: 5,
        type:"spring"
       
      }} 
   
      

    
      >
        <Image
    src={eLogoImg}
    alt=""
    // backgroundColor="red"
   
    width={matches ? "400px" : "300px"}
    height={matches ? "400px" : "300px"}
    objectFit="cover"
    position="relative"
    />
      </motion.div>
       </Flex> */}

       <Flex
       position="relative"
      //  display="block"
       width="100%"
       height="100%"
       backgroundColor="#0C8645"
      // backgroundColor="pink"
       justifyContent="flex-end"
       alignItems="end"
       >
        <section
  
       
  className='flex flex-col  w-[60vw] pt-5 pb-5  pl-8 pr-8  '
  >

   <motion.section
   
   initial={{
   
 
 
 
   

     opacity: 0.3,
     scale: 0.8
     
   }}
   whileInView={{
     opacity: 1,
     scale: 1
     
    
     
   }}
   
   transition={{ duration: 2 }}>

   
   
   <Text
    className='relative sm: text-3xl md:text-[63px]' 

    
   //  width="100%"

    fontSize={matches ? "35px" : "25px"}
     color="white"
    >
   OUR VISION
   </Text>
   </motion.section>

   <div className='  sm:text-xl text-white sm:pt-0 md:text-[22px] md:pt-0 '>
   To normalize the act of pouring into your neighbor to create a legacy one can be proud of.
   </div>
  
       </section>

       </Flex>


    </Grid>

{/* Upcoming Events Section */}
    <Flex

    width="100%"
     height="auto"
  //  backgroundColor="pink"
    position="relative"
    direction="column"
    id='upcoming-events'
    overflow="scroll"
    // justifyContent="flex-start"
    // paddingTop="50px"
    
    paddingLeft="10px"
    paddingRight="10px"
    
    >

      <Text
      position="relative"
      fontSize="40px"
      color="white"
      // backgroundColor="red"
      // marginTop="60px"
      paddingLeft="10px"
      >
        Upcoming Events ...
      </Text>

     
    <Grid container
    position="relative"
    display="flex"
    direction="row"
    wrap="nowrap"
    width="auto"
    
    gap="20px"
    // backgroundColor="brown"

    height="auto"
    // justifyContent={matches ? "center" : "flex-start"}
    justifyContent="flex-start"
    paddingTop="25px"
    paddingLeft="20px"
    paddingRight="20px"
    
    alignContent="center"
    paddingBottom="25px"
    sx={{
      overflowY:"scroll"
    }}
    
    // alignContent="center"
    // backgroundColor="red"
    >

  
    <motion.div
      initial={{
        opacity: 0.6,
        scale: 0.8
     
      }}
        whileInView={{
        
        opacity: 1,
        scale: 1

      }}
      transition={{
        duration: 2,
        type:"spring"
       
      }} 
   
      

    
      >
    <Grid container
        
        position="relative"
        display="flex"
        direction="column"
        borderRadius="15px"
        backgroundColor="rgba(255,255,255,.9)"
        wrap="nowrap"
        // zIndex="9999px"
        // className=' flex-col '
        

        
        width="300px"
        height="550px"
        // backgroundColor="yellow"
        
        >
  <Image
  // src='/skteevent.JPG'
  borderRadius="15px"
  // src='/schoolDrive.jpg'
  src={img3}
  height="480px"
  width="600px"
  />

  <Flex
  position="relative"
  justifyContent="center"
  alignItems="center"
  direction="column"
  paddingLeft="5px"
  paddingRight="5px"
  paddingTop="25px"
  paddingBottom="25px">


  <Text 
  width="100%"
  textAlign="center"
  fontSize="20px"
  
  >
    Sign up to volunteer now!
  </Text>
   <motion.button
       

       whileHover={{ scale: 1.1 }}
       whileTap={{ scale: 0.9 }}
       onClick={() => {
           navigate('/VolunteerSignUp')
       }}>
           <Text
           display="flex"
           justifyContent="center"
           alignItems="center"
           width="200px"
           height="40px"
           backgroundColor="rgba(32, 78, 41, 0.8)"
           borderRadius="5px"
           textAlign="center"
           fontWeight="bold"
           color="white"
           >
           Sign Up
           </Text>
  </motion.button>
  </Flex>
    </Grid>
   </motion.div>

    <motion.div
      initial={{
        opacity: 0.6,
        scale: 0.8
     
      }}
        whileInView={{
        
        opacity: 1,
        scale: 1

      }}
      transition={{
        duration: 2,
        type:"spring"
       
      }} 
   
      

    
      >
    <Grid container
        
        position="relative"
        display="flex"
        direction="column"
        borderRadius="15px"
        backgroundColor="rgba(255,255,255,.9)"
        wrap="nowrap"
        // zIndex="9999px"
        // className=' flex-col '
        

        
        width="300px"
         height="550px"
        // backgroundColor="yellow"
        
        >
  <Image
  // src='/skteevent.JPG'
  borderRadius="15px"
  // src='/schoolDrive.jpg'
  src={img4}
  height="480px"
  width="600px"
  />

  <Flex
  position="relative"
  justifyContent="center"
  alignItems="center"
  direction="column"
  paddingLeft="5px"
  paddingRight="5px"
  paddingTop="25px"
  paddingBottom="25px">


  <Text 
  width="100%"
  textAlign="center"
  fontSize="20px"
  
  >
    Plug in!
  </Text>


   <motion.button
       

       whileHover={{ scale: 1.1 }}
       whileTap={{ scale: 0.9 }}
       onClick={() =>{
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSeqXmdfnHQPSGH0r1DAZYvltkVL5gcj-Se5Ts9WFYXgdOzNlA/viewform?usp=sf_link")
        
      }}
       >
           <Text
           display="flex"
           justifyContent="center"
           alignItems="center"
           width="200px"
           height="40px"
           backgroundColor="rgba(32, 78, 41, 0.8)"
           borderRadius="5px"
           textAlign="center"
           fontWeight="bold"
           color="white"
           >
           Sign Up
           </Text>
  </motion.button>

  </Flex>
    </Grid>
   </motion.div>

   <motion.div
      initial={{
        opacity: 0.6,
        scale: 0.8
     
      }}
        whileInView={{
        
        opacity: 1,
        scale: 1

      }}
      transition={{
        duration: 2,
        type:"spring"
       
      }} 
   
      

    
      >
    <Grid container
        
        position="relative"
        display="flex"
        direction="column"
        borderRadius="15px"
        backgroundColor="rgba(255,255,255,.9)"
        wrap="nowrap"
        // zIndex="9999px"
        // className=' flex-col '
        

        
        width="300px"
         height="550px"
        // backgroundColor="yellow"
        
        >
  <Image
  // src='/skteevent.JPG'
  borderRadius="15px"
  // src='/schoolDrive.jpg'
  src={img5}
  height="480px"
  width="600px"
  />

  <Flex
  position="relative"
  justifyContent="center"
  alignItems="center"
  direction="column"
  paddingLeft="5px"
  paddingRight="5px"
  paddingTop="25px"
  paddingBottom="25px">


  <Text 
  width="100%"
  textAlign="center"
  fontSize="20px"
  
  >
    Sign up skater!
  </Text>


   <motion.button
       

       whileHover={{ scale: 1.1 }}
       whileTap={{ scale: 0.9 }}
       onClick={() =>{
        navigate('/SkaterSignUp')
      }}
       >
           <Text
           display="flex"
           justifyContent="center"
           alignItems="center"
           width="200px"
           height="40px"
           backgroundColor="rgba(32, 78, 41, 0.8)"
           borderRadius="5px"
           textAlign="center"
           fontWeight="bold"
           color="white"
           >
           Sign Up
           </Text>
  </motion.button>

  </Flex>
    </Grid>
   </motion.div>

   <motion.div
      initial={{
        opacity: 0.6,
        scale: 0.8
     
      }}
        whileInView={{
        
        opacity: 1,
        scale: 1

      }}
      transition={{
        duration: 2,
        type:"spring"
       
      }} 
   
      

    
      >
    <Grid container
        
        position="relative"
        display="flex"
        direction="column"
        borderRadius="15px"
        backgroundColor="rgba(255,255,255,.9)"
        wrap="nowrap"
        // zIndex="9999px"
        // className=' flex-col '
        

        
        width="300px"
         height="550px"
        // backgroundColor="yellow"
        
        >
  <Image
  // src='/skteevent.JPG'
  borderRadius="15px"
  // src='/BrownsvilleClinic.jpg'
  src={img2}
  height="480px"
  width="600px"
  />

  <Flex
  position="relative"
  justifyContent="center"
  alignItems="center"
  direction="column"
  paddingLeft="5px"
  paddingRight="5px"
  paddingTop="25px"
  paddingBottom="25px">


  <Text 
  width="100%"
  textAlign="center"
  fontSize="20px"
  
  >
    Sign up your skater today!
  </Text>
   <motion.button
       

       whileHover={{ scale: 1.1 }}
       whileTap={{ scale: 0.9 }}
       onClick={() =>{
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSePbc24N3psTbMBRSbBRK0820IRNkop57MDtb04rP6xgNIaqA/viewform?usp=sf_link")
        
      }}
       
       >
           <Text
           display="flex"
           justifyContent="center"
           alignItems="center"
           width="200px"
           height="40px"
           backgroundColor="rgba(32, 78, 41, 0.8)"
           borderRadius="5px"
           textAlign="center"
           fontWeight="bold"
           color="white"
           >
           Sign Up
           </Text>
  </motion.button>
  </Flex>
    </Grid>
   </motion.div>

   <motion.div
      initial={{
        opacity: 0.6,
        scale: 0.8
     
      }}
        whileInView={{
        
        opacity: 1,
        scale: 1

      }}
      transition={{
        duration: 2,
        type:"spring"
       
      }} 
   
      

    
      >
    <Grid container
        
        position="relative"
        display="flex"
        direction="column"
        borderRadius="15px"
        backgroundColor="rgba(255,255,255,.9)"
        wrap="nowrap"
        // zIndex="9999px"
        // className=' flex-col '
        

        
        width="300px"
         height="550px"
        // backgroundColor="yellow"
        
        >
  <Image
  // src='/skteevent.JPG'
  borderRadius="15px"
  // src='/schoolDrive.jpg'
  src={img1}
  height="480px"
  width="600px"
  />

  <Flex
  position="relative"
  justifyContent="center"
  alignItems="center"
  direction="column"
  paddingLeft="5px"
  paddingRight="5px"
  paddingTop="25px"
  paddingBottom="25px">


  <Text 
  width="100%"
  textAlign="center"
  fontSize="20px"
  
  >
    Sign up skater!
  </Text>


   <motion.button
       

       whileHover={{ scale: 1.1 }}
       whileTap={{ scale: 0.9 }}
       onClick={() =>{
        navigate('/SkaterSignUp')
      }}
       >
           <Text
           display="flex"
           justifyContent="center"
           alignItems="center"
           width="200px"
           height="40px"
           backgroundColor="rgba(32, 78, 41, 0.8)"
           borderRadius="5px"
           textAlign="center"
           fontWeight="bold"
           color="white"
           >
           Sign Up
           </Text>
  </motion.button>

  </Flex>
    </Grid>
   </motion.div>

  

   {/* <motion.div
      initial={{
        opacity: 0.6,
        scale: 0.8
     
      }}
        whileInView={{
        
        opacity: 1,
        scale: 1

      }}
      transition={{
        duration: 2,
        type:"spring"
       
      }} 
   
      

    
      >
    <Grid container
        
        position="relative"
        display="flex"
        direction="column"
        borderRadius="15px"
        backgroundColor="rgba(255,255,255,.9)"
        wrap="nowrap"
        // zIndex="9999px"
        // className=' flex-col '
        

        
         width="300px"
        height="620px"
        // backgroundColor="yellow"
        
        >
  <Image
  // src='/skteevent.JPG'
  borderRadius="15px"
  // src='/5r.jpg'
  src={img1}
  height="480px"
  width="600px"
  />

  <Flex
  position="relative"
  justifyContent="center"
  alignItems="center"
  direction="column"
  paddingLeft="5px"
  paddingRight="5px"
  paddingTop="25px"
  paddingBottom="25px">


  <Text 
  width="100%"
  textAlign="center"
  fontSize="20px"
  
  >
    Sign up to volunteer now!
  </Text>
   <motion.button
       

       whileHover={{ scale: 1.1 }}
       whileTap={{ scale: 0.9 }}
       onClick={() => {
           navigate('/VolunteerSignUp')
       }}>
           <Text
           display="flex"
           justifyContent="center"
           alignItems="center"
           width="200px"
           height="40px"
           backgroundColor="rgba(32, 78, 41, 0.8)"
           borderRadius="5px"
           textAlign="center"
           fontWeight="bold"
           color="white"
           >
           Sign Up
           </Text>
  </motion.button>
  </Flex>
    </Grid>
   </motion.div> */}

   


    </Grid>
    

    </Flex>

{/* 501c3 tab */}
    <Grid container
    position="relative"
    display="flex"
    width="100%"
    height="auto"
    paddingTop="50px"
    // backgroundColor="green"
    
    >
      <div
      className=' relative w-full h-auto'>
      <div 
      className='w-full text-white  pl-5 pb-3 pr-5'>
        Environmental Hood Restoration is a 501c3 based in NYC
        

      </div>
      </div>

    </Grid>

    </Grid>
      
  )
}
